import React, { useState } from 'react';
import { bool } from 'yup';

import axios from '../lib/axios';
import { sendData } from '../lib/auth';
// import { spinner } from '../lib/fetching-util';

const AuthContext = React.createContext({
  token: '',
  isLoggedIn: false,
  id: '',
  isAdmin: bool,
  name: '',
  niy: '',
  unit: '',
  jenkel: '',
  noHp: '',
  status: null,
  deviceToken: '',
  login: ({ token, user }) => {},
  logout: () => {},
});

const getUserData = () => {
  let user, token;
  try {
    user = JSON.parse(localStorage.getItem('simakUser'));
    token = localStorage.getItem('accessDeiToken');
  } catch (error) {
    user = null;
    token = null;
  }

  return {
    user,
    token,
  };
};

let logoutTimer;

export const AuthContextProvider = props => {
  const userData = getUserData();

  const [token, setToken] = useState(userData?.token || null);
  const [user, setUser] = useState(userData?.user || null);
  // const [isLoading, setIsLoading] = useState(false);

  const userIsLoggedIn = !!token;
  const numIsAdmin = user?.is_admin;
  const userIsAdmin = !!(numIsAdmin && +numIsAdmin === 502);
  const id = user?.id;
  const name = user?.name;
  const niy = user?.niy;
  const unit = user?.unit;
  const jenkel = user?.jenkel;
  const noHp = user?.noHp;
  const status = +user?.status;
  const deviceToken = user?.device_token;
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;

  const logoutHandler = () => {
    sendData('logout', null, 'POST').finally(() => {
      setUser(null);
      setToken(null);
      if (logoutTimer) clearTimeout(logoutTimer);
      localStorage.removeItem('simakUser');
      localStorage.removeItem('accessDeiToken');
      localStorage.removeItem('expTime');
      delete axios.defaults.headers.common.Authorization;
    });
  };

  const loginHandler = ({ user, token }) => {
    localStorage.setItem('accessDeiToken', token);
    localStorage.setItem('simakUser', JSON.stringify(user));
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    setToken(token);
    setUser(user);
  };

  // useEffect(() => {
  //   if (!token) {
  //     const myToken = localStorage.getItem('accessDeiToken');
  //     setToken(myToken);
  //     setUser(JSON.parse(localStorage.getItem('simakUser')));
  //     axios.defaults.headers.common.Authorization = `Bearer ${myToken}`;
  //   }

  //   setIsLoading(false);
  // }, []);

  const contextValue = {
    isLoggedIn: userIsLoggedIn,
    isAdmin: userIsAdmin,
    id,
    name,
    niy,
    status,
    deviceToken,
    unit,
    jenkel,
    noHp,
    ...user,
    login: loginHandler,
    logout: logoutHandler,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {/* {isLoading && spinner} */}
      {/* {!isLoading && props.children} */}
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
